import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            // 메인
            "쿨차지": "Koolcharge",
            "쿨차지충전기": "Koolcharge Charger",
            "쿨차지플랫폼": "Koolcharge Platform",
            "기술지원": "Support",
            "쿨차지 앱 다운로드": "Download Koolcharge App",
            "기업정보": "Business Information",
            "비공용 완속 충전기": "Private Slow Charger",
            "공용 완속 충전기": "Public Slow Charger",
            "충전소 관리": "Charge Station Management",
            "충전소 화재감지": "Charge Station Fire Detection",
            "충전소 통합정보": "Charge Station Information",
            "충전소 보안": "Charge Station Security",
            "자료실": "Resources",
            "대리점모집": "Dealership",
            // 장치
            "나를 알아보는 충전기": "A charger that recognizes me",
            "내 차를 지켜주는 충전기": "A charger that protects my car",
            "동급최강의 탁월한 편의성": "The best convenience",
            "충전기 전면의 고해상도 카메라와 AI기반 분석기술로 내 차 번호판, 내 얼굴을 인식하는똑똑한 충전기": "A smart charger that recognizes vehicle number and my face with a front high-resolution camera using AI-based analyze.",
            "충전기에 탑재 된 지능형 CCTV가 충전 중 발생하는 돌발 상황을 감지하고 녹화해서 사고, 화재를 감시": "A built-in camera with intelligent CCTV captures accidents during charging and detects fire.",
            "QR코드 및 NFC기반의 간편결제 연동": "QR and NFC-based simple payment are integrated.",
            "콤팩트한 본체와 저발열 설계": "Compact body and low-heating architecture.",
            "전면 개폐 구조로 간편한 A/S": "Front opening architecture that allows easy A/S.",
            "쿨차지 전용 모바일앱으로 손쉬운 서비스 업데이트": "Easy service update with Koolcharge app.",
            "<0>KCA7</0> 충전기는 <0>7KW</0> 용량으로 개인 주택 및 사무실에 설치하여 언제든지 원하는 대로 충전할 수 있는 모델입니다.": "The <0>KCA7</0> charger is a <0>7KWH</0> charger that you can charge whenever you want by installing on your resident building or an office.",
            "충전 상태 표시 LED (대기/충전/완료/오류)": "A charging status LED(Available/Charging/Finished/Error)",
            "단독, 다가구, 공동주택 및 사업장 등의 비과금형 충전용": "Non-payment charger for individuals, multiple residents, shared residents and offices.",
            "RF 태그를 이용한 사용자 인증": "User authorization with an RF tag.",
            "<0>KCA7P</0> 충전기는 <1>7KW</1> 용량으로 사업장, 공용주차장, 쇼핑몰등에 설치하여 수익 사업이 가능한 모델입니다.": "<0>KCA7P</0> Charger is a <0>7KWH</0> that you can generate profit by installing on offices, public parking lots and shopping malls.",
            "충전 상태 표시 디스플레이 (8” 컬러 LCD)": "Charging status display(8” Color LCD)",
            "다양한 결제 방식 지원 (멤버십카드, 페이온 후불교통카드, 삼성페이)": "Supports various payment methods(membership cards, Payon public transit cards, Samsung Pay)",
            "모델명": "Model name",
            "입력": "Input",
            "출력": "Output",
            "인증방식": "Authorization",
            "RF CARD 및 신용카드 결재": "RF card and credit card",
            "충전시간": "Charging time",
            "10시간 이내 (64kWh기준)": "About 10 hours(based on 64kWh)",
            "크기": "Size",
            "인렛 구조": "Inlet structure",
            "충전 차량": "Chargable vehicle",
            "표준 5PIN 차량": "5PIN Standard vehicle",
            "인증": "Certificate",
            "KC / 형식승인 / OCPP 1.6 인증": "KC / Type Approved / OCPP 1.6 Certified",
            "IP등급": "IP Grade",
            "본체 : IP65 / 커플러 : IP44": "Body : IP65 / Coupler : IP44",
            "설치방식": "Installation",
            "월박스 및 스탠드 모두 가능": "Wallbox and stand available",
            "<0>KCA11</0> 충전기는 <1>11KW</1> 용량으로 개인 주택 및 사무실에 설치하여 언제든지 원하는 대로 충전할 수 있는 모델입니다.": "The <0>KCA11</0> charger is a <0>11KWH</0> charger that you can charge whenever you want by installing on your resident building or an office.",
            "<0>KCA11P</0> 충전기는 <1>11KW</1> 용량으로 사업장, 공용주차장, 쇼핑몰등에 설치하여 수익 사업이 가능한 모델입니다.": "<0>KCA11P</0> Charger is a <0>11KWH</0> that you can generate profit by installing on offices, public parking lots and shopping malls.",
            "충전기 동작상태 음성 안내 스피커": "A speaker that informs the charger's status",
            "6시간 이내 (64kWh기준)": "About 6 hours(based on 64kWh)",
            "중속 충전기": "Medium Speed Charger",
            "<0>KCD35</0> 충전기는 <0>35kW</0> 용량의 중속 충전기로 백화점, 대형 마트, 쇼핑몰 등의 고객 이용 패턴에 최적화된 모델입니다.": "<0>KCD35</0> charger is a <0>35kW</0> charger that is optimized for department stores, supermarkets, shopping malls.",
            "충전 상태 표시 디스플레이 (12” 컬러 LCD)": "Charging status display(12” Color LCD)",
            "다양한 결제 방식 지원 (멤버십카드, 신용카드, 삼성페이)": "Supports various payment methods(membership cards, credit cards, Samsung Pay)",
            "AI기반 자동차 번호 인식 및 화재 감시 기능": "AI-based vehicle number recognition and fire detection",
            "95% 이상 / 0.95 이상": "Above 95% / above 0.95",
            "누전차단기 80A , 30mA": "Circuit breaker 80A, 30mA",
            "급속 충전기": "Rapid Speed Charger",
            "<0>KCD50/100</0> 충전기는 <0>50/100kW</0> 용량의 급속 2채널 충전기로 전용 충전소, 사옥, 주차장, 백화점, 대형 마트, 쇼핑몰 등 모든 장소에 설치 가능한 모델입니다.": "<0>KCD50/100</0> charger is a <0>50/100kW</0> 2 channel charger that you can install any places, dedicated stations, headquarters, parking lots, department stores, supermarkets, and shopping malls.",
            "충전 상태 및 광고 디스플레이 (27” 터치스크린)": "Charging status display(27” touch screen)",
            "2채널 스마트 충전": "2 channel start charging",
            "효율 / 역율": "Efficiency / Power Factor",
            "통신방식": "Communication method",
            "차단기 용량": "Circuit breaker capacity",
            "1CH 충전": "1 channel charging",
            "2CH 동시 충전": "2 channel simultaneous charging",
            "본체 : IP44 / 커플러 : IP54": "Body : IP44 / Coupler : IP54",
            "누전차단기 100A , 30mA": "Circuit breaker 100A , 30mA",
            "누전차단기 200A , 30mA": "Circuit breaker 200A , 30mA",
            "독보적 AI 기술로 더욱 스마트한": "Smarter with unmatched AI technology",
            "EV 충전 솔루션 <1>쿨차지</1>": "EV charge solution <1>KOOLCHARGE</1>",
            // 쿨차지플랫폼
            "충전소 관리 시스템 (CSMS)": "Charge Station Management System(CSMS)",
            "충전소 화재감시 시스템 (CSFDS)": "Charge Station Fire Detection System(CSDFS)",
            "충전소 통합정보 시스템 (CSIS)": "Charge Station Information System(CSIS)",
            "충전소 보안 시스템 (CSSS)": "Charge Station Security System(CSSS)",
            "충전 사업 운영자 관리 시스템": "Charge-Point Operator Management System",
            "충전소 등록 관리 – 주소, 운영자, 이용시간, 요금": "Charging station registration management – address, operator, usage hours, fee",
            "충전소 별 충전기 현황 및 관리": "Charger status and management by charging station",
            "서비스 버전 확인, 원격 업데이트": "Check service version, remote update",
            "장애 관리, 원격 점검": "Failure management, remote inspection",
            "관리자 웹/모바일 알림/푸시 서비스": "Administrator web/mobile notification/push service",
            "사용자 관리시스템 (web/mobile App.)": "User Management System (Web/Mobile App)",
            "충전소 검색 및 길 찾기 기능": "Charging station search and route finding function",
            "충전소 이용 현황, 충전 가능 상태": "Charging station usage status, charging availability status",
            "차량 등록 시스템 및 자동 충전 개시": "Launch of vehicle registration system and automatic charging",
            "충전/완충 안내 및 중단 시 사용자 확인 서비스": "Charging/recharging guidance and user confirmation service in case of interruption",
            "충전기 예약 서비스 및 푸시 알림": "Charger reservation service and push notifications",
            "월별 충전 요금, 전력 사용량 확인": "Check monthly charging fee and power usage",
            "결제 내역 및 포인트 충전, 사용 내역 확인": "Check payment history, point recharge, and usage history",
            "NFC 후불교통카드/삼성페이 결제 지원": "NFC postpaid transportation card/Samsung Pay payment support",
            "CSMS:<0/>운영 시스템": "CSMS:<1/>Operating System",
            "B2B 충전사업 운영자 관리 시스템": "B2B Charging Business Operator Management System",
            "기간 별 전력 사용량": "기간 별 전력 사용량",
            "평균 가동 현황 및 유휴 시간대": "Average operation status and idle time",
            "장애 관리 – 유형 별 알림, 대시보드, 원격 점검": "Failure management – notifications, dashboard, remote inspection by type",
            "주소, 커넥터 타입, 전력 사용량, 사용 시간": "Address, connector type, power usage, usage time",
            "CSMS:<1/>사용자 서비스": "CSMS:<1/>User Services",
            "사용자 Web/Mobile 서비스": "User Web/Mobile Service",
            "충전소 검색, 길찾기 기능": "Charging station search, route finding function",
            "멤버십 카드 발급": "Issuance of membership card",
            "전용 모바일 앱 제공": "Dedicated mobile app provided",
            "화재 예방 충전기": "Fire prevention charger",
            "NFC리더 자체 개발-삼성페이/애플페이/후불교통카드 지원, 국내 최고 IP65/68 등급, 산업용 규격 AP 적용": "Self-developed NFC reader - Samsung Pay/Apple Pay/postpaid transportation card support, Korea's highest IP65/68 rating, industrial standard AP applied",
            "KC안전인증, 형식승인, 환경부 ISO15118/OCPP 통신프로토콜 시험 진행 중(’24년 10월 초 완료 예정)": "KC safety certification, type approval, Ministry of Environment ISO15118/OCPP communication protocol test in progress (scheduled to be completed in early October 2024)",
            "NFC리더 자체 개발": "Self-developed NFC reader",
            "삼성/애플페이 교통카드": "Samsung/Apple Pay transportation card",
            "삼성페이, 애플페이, 페이온후불교통카드": "Samsung Pay, Apple Pay, PayOn Postpaid Transportation Card",
            "삼성페이, 애플페이": "Samsung Pay, Apple Pay",
            "산업용 규격": "Industrial standards",
            "Industrial 등급 AP 탑재": "Equipped with industrial-grade AP",
            "산업용 등급 AP 탑재로 내구성 강화": "Enhanced durability with industrial-grade AP",
            "칩셋 동작온도 -40 ℃ ~ 80 ℃": "Chipset operating temperature -40℃ ~ 80℃",
            "규격대비 고용량 부품 적용 설계": "Designed to apply high-capacity parts compared to specifications",
            "고장방지 설계": "Failure-proof design",
            "비접촉식 CT센서 적용 부식 방지": "Non-contact CT sensor applied to prevent corrosion",
            "팩토리모드 지원": "Factory mode support",
            "국내 최고 IP65등급 이상": "Korea’s highest IP65 rating or higher",
            "본체 IP65 및 난연 코팅": "Body IP65 and flame retardant coating",
            "커플러 IP68 등급 획득": "Coupler IP68 rated",
            "2024년 환경부 화재예방형 충전기 기준 발표": "Ministry of Environment announces fire prevention charger standards in 2024",
            "충전 중 배터리 상태정보(전압, 전류, 온도, SoC 등) 수집하여 CSMS에 전송": "Collects battery status information (voltage, current, temperature, SoC, etc.) during charging and transmits it to CSMS",
            "충전 제어 정보를 수신하여 실시간 충전 제어": "Real-time charging control by receiving charging control information",
            "오토 스위칭": "Auto switching",
            "ISO 15118 충전 실패 시 IEC 61851-1 PWM으로 스위칭": "Switching to IEC 61851-1 PWM on ISO 15118 charging failure",
            "오토 스탑": "Auto stop",
            "SOC 85% 도달 시 충전 중단 및 재충전 금지": "Charging stops and recharging is prohibited when SOC reaches 85%",
            "(최대 충전량은 설정 가능)": "(maximum charge amount can be set)",
            "OTA 업데이트": "OTA updates",
            "SECC 및 EVSE F/W OTA 업데이트 지원": "SECC and EVSE F/W OTA update support",
            "OBD2 지원": "OBD2 support",
            "OBD2 기반 BMS 정보 수집 지원": "Supports OBD2-based BMS information collection",
            "BT/WiFi 데이터 전송 지원": "Support BT/WiFi data transmission",
            "PLC 모뎀 일체형 AC충전기, Vision AI기반 화재감시/CCTV/블랙박스 기능": "PLC modem-integrated AC charger, Vision AI-based fire surveillance/CCTV/black box function",
            "충전기 탑재 카메라로": "Charger with camera built-in",
            "블랙박스 / 화재감지 / 차량번호 인식 / CCTV": "Blackbox / Fire Detection / Vehicle Number Recognition / CCTV",
            "AI 바우처 공급업체": "AI Voucher Supplier",
            "K-NBTC 인증(KISA 바이오인식시스템 성능 인증)": "K-NBTC certification (KISA biometric recognition system performance certification)",
            "Vision AI 화재감시": "Vision AI fire monitoring",
            "객체 탐지-식별 기술 기반의 화재 탐지 기능": "Fire detection function based on object detection-identification technology",
            "차량번호 식별": "Vehicle number identification",
            "영상 기반 차량 식별 및 주차 시스템 연동 지원": "Supports video-based vehicle identification and parking system linkage",
            "지능형 CCTV": "Intelligent CCTV",
            "객체 탐지 기반 CCTV 및 VMS 개발 공급": "Object detection-based CCTV and VMS development and supply",
            "충전기술 특허 현황": "Charging Technology Patent Status",
            "고효율 분산 전력 공급 충전시스템, 10-2691919": "High efficiency distributed power supply charging system, 10-2691919",
            "차량 정보 인식 기반의 플러그 앤 차지를 수행하기 위한 시스템 및 이를 위한 방법, 10-2686980": "System and method for performing plug and charge based on vehicle information recognition, 10-2686980",
            "위험 감지 기반 자동 전력차단시스템, 10-2684494": "Danger detection-based automatic power-off system, 10-2684494",
            "가변형 충전 전력공급시스템, 10-2024-0087771": "Variable charging power supply system, 10-2024-0087771",
            "화재 예방 교류충전기, 10-2024-0071919": "Fire prevention AC charger, 10-2024-0071919",
            "충전소 화재 예방 시스템": "Charging Station Fire Prevention System",
            "열화상 및 RGB 듀얼 카메라를 이용한 화재 판별 장치 및 방법, 10-2654042": "Fire Detection Device and Method Using Thermal Imaging and RGB Dual Camera, 10-2654042",
            "엣지 디바이스에서 생성한 객체 탐지 이벤트 기반의 영상 분류 시스템, 10-2411209": "Image Classification System Based on Object Detection Events Generated by Edge Devices, 10-2411209",
            "클라우드 엣지 기반의 영상 분석 시스템, 10-2456189": "Cloud Edge-Based Video Analysis System, 10-2456189",
            "측정온도 보정시스템, 10-2220654": "Measurement Temperature Correction System, 10-2220654",
            "THERMAL+RGB 듀얼 센서": "THERMAL + RGB Dual Sensor",
            "열화상+RGB 카메라 영상을 Edge에서 분석": "Analyzes thermal and RGB camera images at the edge",
            "딥러닝 학습 모델 기반 영상 탐지 식별 기술 적용": "Application of deep learning-based image detection and identification technology",
            "RGB 영상과 열화상을 분석하여 2중 화재 감지": "Double fire detection by analyzing RGB images and thermal images",
            "Edge 기반 추론-탐지로 네트워크 장애에 대응": "Responds to network failures with edge-based inference-detection",
            "전용 NPU 탑재로 빠르고 정확한 추론 성능 제공": "Provides fast and accurate inference performance with dedicated NPU",
            "화재감지 VMS": "Fire Detection VMS",
            "Edge에서 감지한 이벤트 영상 확인": "Check event video detected by Edge",
            "화재 발생 시 관리자 알림/푸시 서비스": "Administrator notification/push service in case of fire",
            "AI Edge 상태 모니터링": "Monitoring",
            "픽셀 별 온도 측정/알람 온도 설정 기능 지원": "Supports pixel-specific temperature measurement/alarm temperature setting function",
            "충전소 내 요주의 장비/지역 발열 모니터링": "Monitors temperature of critical equipment/areas within the charging station",
            "환경": "Environment",
            "가상환경": "Virtual Environment",
            "물리적 환경": "Physical Environment",
            "(하드웨어, 네트워크, 백업 등을 직접 혹은 IDC 일부 위탁)": "(Directly or partially outsource hardware, network, backup, etc. to IDC)",
            "비용": "Cost",
            "초기투자": "Initial Investment Cost",
            "없음": "None",
            "높음": "High",
            "유지비용": "Maintenance Cost",
            "보안": "Security",
            "일정 수준이상의 보안, 규정 준수": "Security above a certain level, compliance with regulations",
            "데이터 보호 및 보안 부담 경감": "Reduce the burden of data protection and security",
            "완벽한 시스템 제어권": "Take full control of your system",
            "보안 관리 투명성 보장": "Ensure security management transparency",
            "운영": "Operation",
            "CSP에 위임": "Delegate operation to CSP",
            "직접 운영 또는 IDC 일부 위탁": "Direct operation or partially entrust to IDC",
            "확장성": "Scalability",
            "맞춤형 시스템": "Customized system",
            "정기적 인프라 투자": "Regular infrastructure investment",
            "사용량기반 요금": "Usage-based fee",
            "데이터백업 자동화": "Data backup automation",
            "무중단 서비스": "Uninterrupted service",
            "지상 주차 솔루션": "Ground Parking Solutions",
            "상가 / 아파트": "Commercial/apartment",
            "상가/아파트 지상주차솔루션 제안": "Proposal of ground parking solutions for commercial/apartment",
            "태양광 카포트 + AC 충전기 구성": "Solar carport + AC charger configuration",
            "태양광 패널-인버터-스마트 스위치-AC 충전기": "Solar panel-inverter-smart switch-AC charger",
            "전력 계통 구성이 쉽고, 충전 수요가 많은 장소": "Locations where power system configuration is easy and charging demand is high",
            "스마트 스위치로 태양광과 한전 간 인입 전력 스위칭": "Switching incoming power between solar power and KEPCO with a smart switch",
            "BSS 용량 감축 또는 미설치 가능": "BSS capacity can be reduced or not installed",
            "졸음쉼터 / 도서·산간지역": "Drowsiness Rest Area/Island/Mountain Ground",
            "졸음쉼터/도서·산간 지상주차솔루션 제안": "Proposal for Drowsiness Rest Area/Island/Mountain Ground Parking Solutions",
            "태양광 카포트 + DC-DC 충전기 구성": "Solar carport + DC-DC charger configuration",
            "태양광 패널-BSS-DC2DC 충전기": "Solar panel-BSS-DC2DC charger",
            "전력 계통 구성이 어렵고, 수요가 적은 장소": "Locations where power system configuration is difficult and demand is low",
            "친환경 에너지 간헐성으로 BSS 추가 필요": "BSS needs to be added due to the intermittency of eco-friendly energy",
            "솔라 카포트 충전 시스템 구축 사례": "Example of Building a Solar Carport Charging System",
            "AVIVA, 스코틀랜드, 솔라카포트 주차장": "AVIVA, Scotland, solar carport parking",
            "덴마크, 고속도로 솔라카포트 충전소": "Denmark, highway solar carport charging station",
            "솔라카포트 충전소": "Solar carport charging station",
            "포르쉐, 솔라카포트 충전소": "Porsche, solar carport charging station",
            "종합 충전 정보 디스플레이 관리": "Comprehensive charging information display management",
            "충전소 디스플레이 (LCD, 전광판)": "Charging station display (LCD, electronic sign)",
            "충전소 안내, 충전기 상태 안내 디스플레이": "Charging station information, charger status information display",
            "충전기 디스플레이": "Charger display",
            "충전기 상태, 충전 정보, 예약 정보 표출관리": "Display management of charger status, charging information, and reservation information",
            "디지털 콘텐츠 관리 시스템": "Digital Content Management System",
            "동영상/이미지/HTML 등 다양한 콘텐츠 지원": "Supports various contents such as video/image/HTML",
            "콘텐츠 관리 및 광고 스케줄 관리 기능": "Content management and advertising schedule management functions",
            "충전기 유휴 시간 광고 편성 기능": "Charger idle time advertisement programming function",
            "충전기-디지털사이니지 통합 관리 지원": "Charger-digital signage integrated management support",
            "충전 정보 + 디지털 사이니지": "Charging Information + Digital Signage",
            "CSMS 연동/통합 형 관리 시스템": "CSMS-linked/integrated management system",
            "충전소 통합 정보 표출": "Display integrated charging station information",
            "충전기별 충전/예약 상태, 잔여 충전시간 표출": "Displays charging/reservation status and remaining charging time for each charger",
            "예약 기기 상태 및 예약자 호출 서비스": "Reservation device status and reservation call service",
            "충전소 통합 정보 관리": "Integrated Charging Station Information Management",
            "충전 가이드, 제휴 충전소 안내 서비스": "Charging guide, affiliated charging station information service",
            "공익/공공 캠페인 영상 표출": "Public interest/public campaign video presentation",
            "간편한 광고 스케쥴 제작/배포 기능": "Easy advertising schedule creation/distribution function",
            "단말/콘텐츠 통계": "Terminal/content statistics",
            "단말 상태 실시간 관제 및 원격 업데이트 서비스": "Real-time terminal status control and remote update service",
            "AI 충전소 보안 시스템": "AI Charging Station Security System",
            "AI 객체인식 기반 차량 번호 인식": "AI object recognition-based vehicle number recognition",
            "자동 결제": "Automatic payment",
            "예약 확인": "Confirm reservation",
            "사용자 확인/안내": "User confirmation/guidance",
            "AI 지능형 CCTV": "AI Intelligent CCTV",
            "이벤트 녹화/전송": "Event recording/transmission",
            "침입/이상 행동 감지": "Intrusion/abnormal behavior detection",
            "KISA 인증 Face detection(K-NBTC 인증)": "KISA-certified face detection (K-NBTC certified)",
            "개인정보(얼굴, 차량 번호 등) 마스킹": "Masking personal information (face, vehicle number, etc.)",
            "원격 관제 서비스": "Remote Control Service",
            "화재감시지원 시스템 연동": "Linkage with fire surveillance support system",
            "이벤트 기반 지능형 관제시스템 제공": "Provides event-based intelligent control system",
            "웹/모바일 알림 서비스": "Web/mobile notification service",
            "엣지 상태 모니터링 및 제어": "Edge status monitoring and control",
            // 문의
            "문의 게시판": "Inquiry Board",
            "항목": "Entries",
            "분류": "Type",
            "제목": "Title",
            "작성자": "Author",
            "등록일": "Date",
            "답변 완료": "Answered",
            "답변": "Answer",
            "글 쓰기": "Write",
            "이름": "Name",
            "문의 유형": "Type",
            "문의 내용": "Content",
            "제품문의": "Product",
            "설치문의": "Installation",
            "기타": "Other",
            "목록": "List",
            "아직 답변이 등록되지 않았습니다.": "Not answered yet.",
            "등록": "Submit",
            // 기타
            "시리즈": "Series",
            "관리자": "Admin",
            "쿨차지 대리점모집": "Koolcharge Dealership",
        }
    }
}

i18n.use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.getItem("lng") || "ko",
        fallbackLng: "ko",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
